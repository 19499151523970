import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { styled, ThemeProvider, createTheme } from '@mui/material/styles';
import { Paper, Box, Typography, Modal, LinearProgress, IconButton, Tooltip } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import { DateTime } from 'luxon';
import Swal from 'sweetalert2';
import { TrackSuccessContext } from '@contexts';
import { EXPENSE_TYPE } from '@utilities/constants';
import { postExpenseGoal, deleteExpenseGoal } from '@helpers/api/tracker';
import CustomInput from '../Inputs/CustomInput';
import CustomSelect from '../Inputs/CustomSelect';
import CurrencyFormat from 'react-currency-format';

const theme = createTheme({
  palette: {
    green: { main: '#4caf50' },
    yellow: { main: '#FFC772' },
    red: { main: '#FF8372' }
  }
});

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4
};

const BorderLinearProgress = styled(LinearProgress)(() => ({
  height: 20,
  borderRadius: 5
}));

const groupByCategory = (rows) => {
  const groupedByCategory = rows.reduce((acc, item) => {
    const category = item.category;
    if (!acc[category]) {
      acc[category] = {
        ...item
      };
    } else {
      acc[category].amount = parseFloat(acc[category]?.amount) + parseFloat(item.amount);
    }
    return acc;
  }, {});
  const resultArray = Object.values(groupedByCategory);
  return resultArray;
};

const colorGauge = (value) => {
  let color = 'green';
  if (value >= 100 || value === 0) {
    value = 100;
    color = 'red';
  }
  if (value >= 80 && value < 100) {
    color = 'yellow';
  }
  return { color, value };
};

export const validationSchema = Yup.object({
  amount: Yup.string()
    .test(
      'maxDigitsAfterDecimal',
      'The trust payment must be a valid number with a maximum of 2 decimal.',
      (number) => /^\d+(\.\d{1,2})?$/.test(number)
    )
    .required('This field is required'),
  category: Yup.string().required('This field is required'),
  description: Yup.string().nullable()
});

const GaugeChart = ({ items, goals, categories, uncategorizedItems, incomeGoals }) => {
  const { setExpenseGoals, month } = useContext(TrackSuccessContext);
  const [chartData, setChartData] = useState([]);
  const [open, setOpen] = useState(false);
  const [formValues, setFormValues] = useState({
    amount: '',
    category: '',
    id: '',
    description: ''
  });

  const itemsByCategory = groupByCategory(items);
  const goalsByCategory = groupByCategory(goals);

  const navigate = useNavigate();

  const uncategorizedItemsByCategory = groupByCategory(uncategorizedItems);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const resetForm = () => {
    setFormValues({
      amount: '',
      category: '',
      id: '',
      description: ''
    });
    handleClose();
  };

  const handleDelete = async (id) => {
    const resultSwal = await Swal.fire({
      title: 'Remove Goal?',
      text: 'Removing this goal will means you no longer expect to spend in this category this month.',
      icon: 'success',
      iconColor: '#6D31EDFF',
      reverseButtons: true,
      showCancelButton: true,
      confirmButtonColor: '#3B6483',
      cancelButtonColor: '#CDCDC9',
      confirmButtonText: 'Confirm',
      cancelButtonText: 'Cancel'
    });
    if (resultSwal.isConfirmed) {
      const result = await deleteExpenseGoal(id)({
        params: {
          month: month ? DateTime.fromISO(month).toJSDate() : null
        }
      });
      if (result.status === 200) {
        Swal.fire({
          title: 'Deleted!',
          text: 'Your expense goal has been deleted.',
          icon: 'success'
        });
        setExpenseGoals(result.data.expense_goals);
      }
    }
  };

  const handleFormSubmit = async (values) => {
    const totalIncomeGoals = incomeGoals.reduce((acc, item) => {
      acc += parseFloat(item.amount);
      return acc;
    }, 0);
    const totalExpenseGoals = goals.reduce((acc, item) => {
      acc += parseFloat(item.amount);
      return acc;
    }, 0);

    const budget = totalIncomeGoals - totalExpenseGoals;

    const formatCurrency = (value) => {
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }).format(value);
    };

    if (budget < Number(values.amount)) {
      Swal.fire({
        title: 'Over Budget',
        text: `This would add ${formatCurrency(Number(values.amount) - budget)} over your budget.`,
        icon: 'error'
      });
      return;
    } else {
      const result = await postExpenseGoal({
        data: {
          month: month ? DateTime.fromISO(month).toJSDate() : DateTime.now().toJSDate(),
          expense_goal: {
            amount: values.amount,
            category: values.category,
            description: values.description,
            effective_date: month ? DateTime.fromISO(month).toJSDate() : DateTime.now().toJSDate()
          }
        }
      }).catch((error) => {
        return;
      });
      setExpenseGoals(result.data.expense_goals);
      resetForm();
      handleClose();
    }
  };

  useEffect(() => {
    const chartArray = itemsByCategory.map((item) => {
      let expenseGoal = goalsByCategory.find((goal) => goal.category === item.category)
      let percentage =
        (item?.amount * 100) /
          expenseGoal?.amount || 0;
      const { color, value } = colorGauge(percentage);
      percentage = value;
      return {
        id: expenseGoal?.id,
        category:
          categories.find((itemCategory) => itemCategory.value === item.category)?.label || 'Other',
        current: item?.amount,
        goal: expenseGoal?.amount || 0,
        currentPercentage: percentage,
        color: color,
        categoryId: item.category
      };
    });

    const chartArrayUncategorized = uncategorizedItemsByCategory.map((item) => {
      let percentage =
        (item?.amount * 100) /
          goalsByCategory.find((goal) => goal.category === item.category)?.amount || 0;
      const { value } = colorGauge(percentage);
      percentage = value;
      return {
        id: item.id,
        category: 'Uncategorized Spendings',
        current: item?.amount,
        goal: 0,
        currentPercentage: percentage,
        color: 'red',
        categoryId: 'uncategorized'
      };
    });

    const goalsWithoutItems = goalsByCategory.reduce((acc, goal) => {
      if (!itemsByCategory.find((item) => item.category === goal.category)) {
        acc.push({
          category:
            categories.find((category) => category.value === goal.category)?.label || 'Other',
          current: 0,
          goal: goal.amount,
          currentPercentage: 0,
          color: 'green',
          categoryId: goal.category,
          id: goal.id
        });
      }
      return acc;
    }, []);

    chartArray.sort((a, b) => (a.category > b.category ? 1 : -1));
    setChartData([...chartArray, ...chartArrayUncategorized, ...goalsWithoutItems]);
  }, [items, goals, uncategorizedItems]);

  return (
    <ThemeProvider theme={theme}>
      <Paper
        sx={{
          width: '100%',
          mb: 2,
          mt: 2,
          p: 2,
          display: 'flex',
          gap: 2,
          flexDirection: 'column'
        }}>
        <Typography
          variant="h6"
          color="text.primary"
          sx={{ fontWeight: 'bold', textAlign: 'left' }}>
          Current Spending By Category
        </Typography>
        {chartData.map((data, index) => (
          <Box
            key={index}
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
              width: '100%',
              gap: '0.5rem'
            }}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%'
              }}>
              <Typography
                variant="body2"
                color="text.primary"
                sx={{ fontWeight: 'bold', width: '38%' }}>
                {data.category}
                {data.category !== 'Trustee Payment' && data.goal !== 0 && (
                  <DeleteOutlineOutlinedIcon
                    sx={{
                      marginRight: 1,
                      cursor: 'pointer',
                      fontSize: '1.5rem',
                      marginBottom: '3px'
                    }}
                    onClick={() => handleDelete(data.id)}
                    titleAccess="Delete"
                  />
                )}
                {data.goal === 0 && (
                  <Tooltip
                    title={
                      <div style={{ fontSize: 14, fontWeight: 'bold' }}>No expense goal set</div>
                    }
                    placement="right">
                    <IconButton>
                      <InfoIcon color="primary" sx={{ fontSize: '1rem' }} />
                    </IconButton>
                  </Tooltip>
                )}
              </Typography>
              <Typography
                variant="body2"
                color="text.primary"
                sx={{ fontWeight: 'bold', width: '32%' }}>
                <CurrencyFormat
                  value={Number(data.current)}
                  displayType="text"
                  thousandSeparator
                  prefix="$"
                  decimalScale={2}
                  className="font-bold"
                  fixedDecimalScale
                />
                {' / '}
                <CurrencyFormat
                  value={Number(data.goal)}
                  displayType="text"
                  thousandSeparator
                  prefix="$"
                  decimalScale={2}
                  decimalSeparator="."
                  fixedDecimalScale
                  className="font-bold"
                />
              </Typography>
              <Typography sx={{ width: '30%' }}>&nbsp;</Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', gap: '1rem' }}>
              <Box sx={{ width: '100%', mr: 1 }}>
                <BorderLinearProgress
                  variant="determinate"
                  value={data.currentPercentage}
                  color={data.color}
                />
              </Box>
              <Box>
                <Typography variant="body2" color="text.secondary">{`${Math.round(
                  data.currentPercentage
                )}%`}</Typography>
              </Box>
              <Box sx={{ minWidth: 300 }}>
                {data.categoryId !== 'uncategorized' && (
                  <button
                    className="bg-greyblue text-white px-4 py-2 rounded hover:bg-greyblue"
                    type="button"
                    onClick={() =>
                      navigate(`/dashboard/tracker/expense-item-by-category/${data.categoryId}`)
                    }>
                    Spending Breakdown
                  </button>
                )}
              </Box>
            </Box>
          </Box>
        ))}
        <div className="flex justify-start items-center gap-4 mb-8">
          <button
            className="bg-teal text-white px-4 py-2 rounded hover:bg-seafoam"
            type="button"
            onClick={() => handleOpen()}>
            + Add additional expense goal
          </button>
        </div>
      </Paper>

      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          <Paper className="p-8">
            <h2 className="font-bold mb-4">Additional Expense Transaction</h2>
            <Formik
              initialValues={formValues}
              validationSchema={validationSchema}
              enableReinitialize
              onSubmit={handleFormSubmit}>
              <Form className="flex flex-col justify-center w-full gap-6 pt-4">
                <div className="flex justify-center items-center gap-4 flex-col">
                  <CustomInput name="amount" placeholder="Amount" icon="fa-dollar-sign" currency />
                  <CustomSelect
                    name="category"
                    placeholder="Expense Category"
                    options={EXPENSE_TYPE.filter(
                      (item) => item.value !== 'cushion' && item.value !== 'trustee_payment'
                    )}
                  />
                </div>
                <div className="flex justify-center items-center gap-4">
                  <button
                    className="bg-seafoam text-white px-4 py-2 rounded hover:bg-teal"
                    type="submit">
                    Save
                  </button>
                  <button
                    className="bg-greige text-white px-4 py-2 rounded hover:bg-greige"
                    type="button"
                    onClick={() => resetForm()}>
                    Cancel
                  </button>
                </div>
              </Form>
            </Formik>
          </Paper>
        </Box>
      </Modal>
    </ThemeProvider>
  );
};

export default GaugeChart;
