import React, { useContext, useState } from 'react';
import { Alert, Box, Button, Paper, Typography } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import CurrencyFormat from 'react-currency-format';
import { TrackerAvatarImg } from '@constants/images';
import { TrackSuccessContext } from '@contexts';
import BreadCrumbComponent from '../Shared/BreadCrumbComponent';
import CustomInput from '../Inputs/CustomInput';
import { postCreateUpdateExpenseGoals } from '@helpers/api/tracker';
import { DateTime } from 'luxon';
import { MonthSelector } from '../Shared/MonthSelector';

const TrusteePayment = () => {
  const { trusteePayment, setExpenseGoals, setTrusteePayment, expenseGoals, incomeGoals, month } =
    useContext(TrackSuccessContext);

  const monthSelected = month
    ? DateTime.fromISO(month).toJSDate()
    : DateTime.now().startOf('month').toJSDate();

  const [alert, setAlert] = useState(false);
  const [valueEnough, setValueEnough] = useState(0);
  const [formAlert, setFormAlert] = useState(false);
  const [alertText, setAlertText] = useState({
    severity: 'success',
    msg: 'Trustee payment successfully updated.'
  });

  const validationSchema = Yup.object({
    amount: Yup.string()
      .test(
        'maxDigitsAfterDecimal',
        'Must be a valid number with a maximum of 2 decimals.',
        (number) => /^\d+(\.\d{1,2})?$/.test(number)
      )
      .required('This field is required')
  });

  const getIncome = () => {
    return incomeGoals.reduce((acc, cur) => Number(acc) + Number(cur.amount), 0);
  };

  const handleFormAlert = (show) => {
    setFormAlert(show);
    setTimeout(() => {
      setFormAlert(false);
    }, 3000);
  };

  const handleEnoughIncome = (values) => {
    const updatedExpenses = [...expenseGoals, values];
    const totalExpenses = updatedExpenses.reduce((acc, cur) => Number(acc) + Number(cur.amount), 0);
    const totalIncomes = incomeGoals.reduce((acc, cur) => Number(acc) + Number(cur.amount), 0);
    setValueEnough(totalExpenses - totalIncomes);
    setAlert(totalExpenses > totalIncomes);
    return totalExpenses > totalIncomes;
  };

  const handleSubmit = async (values) => {
    if (handleEnoughIncome(values)) {
      return false;
    }
    try {
      const response = await postCreateUpdateExpenseGoals({
        data: {
          month: monthSelected,
          expense_goal: {
            items: [values]
          }
        }
      });

      if (response.status === 200) {
        setExpenseGoals(response.data.expense_goals);
        setTrusteePayment(response.data.trustee_payment);
        handleFormAlert(true);
      }
    } catch (error) {
      setAlertText({
        severity: 'error',
        msg: 'Error processing trustee payment. Please try again.'
      });
      handleFormAlert(true);
    }
  };

  return (
    <Box sx={{ width: '100%' }}>
      <MonthSelector>
        <h1 className="title-settings text-dashBoardTopName font-bold text-black text-center my-4">
          Trustee Payment
        </h1>
      </MonthSelector>
      <BreadCrumbComponent />
      <Paper elevation={1} sx={{ p: 3, mt: 1 }}>
        <Box display="flex" mb={1}>
          <img
            src={TrackerAvatarImg}
            alt="Budgeting Buddy"
            className="rounded-full"
            style={{ width: 50, height: 50 }}
          />
          <div className="flex flex-col gap-4">
            <Typography variant="body2" textAlign="justify" sx={{ marginLeft: 1 }}>
              Dollar Bill: “We want to make sure you never miss a trustee payment. How much is you
              month trustee plan payment?
            </Typography>
            <Typography variant="body2" textAlign="justify" sx={{ marginLeft: 1 }}>
              If you do not know the amount you can find this information on your chapter 13 plan or
              by calling your attorney’s office.
            </Typography>
            <Typography variant="body2" textAlign="justify" sx={{ marginLeft: 1 }}>
              The plan payment is the most critical aspect of the chapter 13 process so be sure you
              use www.tfsbillpay.com to create a payment schedule.”
            </Typography>
          </div>
        </Box>
      </Paper>
      <Paper elevation={1} sx={{ p: 3, mt: 2 }}>
        {alert && (
          <div className="mb-6 text-sm bg-redDarker font-bold text-white p-4 rounded-md">
            <p>
              You have entered too many expenses for the month to be able to make your trustee
              payment. Please review your expenses and adjust where necessary to be able to cover
              your trustee payment for the month.
            </p>
            <p className="mt-4 font-bold text-center text-lg">
              Income:{' '}
              <CurrencyFormat
                value={getIncome()}
                displayType="text"
                thousandSeparator
                decimalScale={2}
                prefix="$"
              />
            </p>
            <p className="mt-4 font-bold text-center text-lg">
              Excess in expenses:{' '}
              <CurrencyFormat
                value={valueEnough}
                displayType="text"
                thousandSeparator
                decimalScale={2}
                prefix="$"
              />
            </p>
          </div>
        )}
        <div>
          <h3 className="mb-6 text-xl font-semibold">Update Your Trustee Payment Amount</h3>
          {formAlert && (
            <Alert icon={<CheckIcon fontSize="inherit" />} severity={alertText.severity}>
              {alertText.msg}
            </Alert>
          )}
          <Formik
            initialValues={trusteePayment}
            validationSchema={validationSchema}
            enableReinitialize
            onSubmit={handleSubmit}>
            {({ isSubmitting }) => (
              <Form className="my-8">
                <CustomInput
                  name="amount"
                  placeholder="Trustee Payment Amount"
                  icon="fa-dollar-sign"
                  title="Enter the amount you are scheduled to pay each month as part of your banckruptcy plan"
                  currency
                />
                <div>
                  <Button
                    disabled={isSubmitting}
                    variant="contained"
                    type="submit"
                    sx={{ mt: 1, mr: 1 }}>
                    Confirm
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Paper>
    </Box>
  );
};

export default TrusteePayment;
