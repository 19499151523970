import React, { useState } from 'react';
import { ErrorMessage } from 'formik';
import { RegionDropdown } from 'react-country-region-selector';

import { getClass } from '@helpers/formik';

const RegionSelect = ({ name, value, label, errors, onChange }) => {
  const [selectValue, setSelectValue] = useState(value === null ? '' : value);

  const handleChange = (valueField) => {
    setSelectValue(valueField.target.value);
    onChange(valueField);
  };
  return (
    <div className="relative z-0 w-full max-w-lg mobile:mb-10 mb-7">
      <div className="relative z-0 group">
        <ErrorMessage
          name={name}
          component="div"
          className="mobile:hidden desktop:block tablet:block absolute -top-4 right-0 text-red font-normal text-errorMessageInput"
        />
        <div className="selectdiv">
          <RegionDropdown
            name={name}
            country="US"
            countryValueType="short"
            value={selectValue}
            onChange={(_, val) => handleChange(val)}
            defaultOptionLabel={label}
            valueType="short"
            classes={`${getClass(errors, name)}`}
          />
        </div>
        <ErrorMessage
          name={name}
          component="div"
          className="mobile:block desktop:hidden tablet:hidden absolute top-14 left-0 text-red font-normal text-errorMessageInput"
        />
      </div>
    </div>
  );
};

export default RegionSelect;
