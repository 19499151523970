import React from 'react';

const Card = ({ children, title }) => {
  return (
    <div className="tracker">
      <div className="flex flex-col desktop:mx-10 desktop:p-8 desktop:pb-5 tablet:mx-10 tablet:p-8 tablet:pb-5 mobile:m-0 mobile:p-0 border border-gray-200 gap-4">
        <div className="pb-3 border-b border-gray-200 sm:flex sm:items-start sm:justify-start sm:flex-col gap-6">
          <h3 className="text-descriptionSlide font-bold text-black p-4">{title}</h3>
        </div>
        {children}
      </div>
    </div>
  );
};

export default Card;
