/* eslint-disable react/no-danger */
import React, { useState } from 'react';
import Carousel from 'react-material-ui-carousel';

const Item = ({ item: { src, alt, title, description, showDisclaimer } }) => {
  return (
    <div className="mb-16 !w-4/5 !flex !justify-center !items-center !m-auto desktop:h-[680px]">
      <div className="flex flex-col justify-center items-center">
        <img src={src} alt={alt} className="mx-auto mb-11 w-60" />
        <h2
          className="text-center text-white text-titleSlide mb-[18px]"
          dangerouslySetInnerHTML={{ __html: title }}
        />
        <div
          className="w-2/3 font-normal text-left text-white text-descriptionSlide"
          dangerouslySetInnerHTML={{ __html: description }}
        />
        {showDisclaimer && (
          <div className="text-center mt-24 text-white text-footerElement font-medium">
            * This optional Cash Back Rewards program is not a Pathward product or service, nor is
            it endorsed or supported by Pathward in any way.
          </div>
        )}
      </div>
    </div>
  );
};

const CarouselSlider = ({ images }) => {
  const [firstImgLoaded, setFirstImgLoaded] = useState(false);

  return (
    <div className="w-full desktop:w-2/5 pt-0">
      <img
        src={images[0].src}
        alt={images[0].alt}
        onLoad={() => setFirstImgLoaded(true)}
        style={{ display: 'none' }}
      />
      {firstImgLoaded && (
        <Carousel navButtonsAlwaysInvisible animation="slide" duration="1000">
          {images.map((item, i) => (
            <Item key={i} item={item} />
          ))}
        </Carousel>
      )}
    </div>
  );
};

export default CarouselSlider;
