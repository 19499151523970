import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Box, Radio, Paper, TableRow, TableCell, Typography, Modal } from '@mui/material';
import * as Yup from 'yup';
import { TableComponent, getComparator, stableSort } from './GenerateTable';
import ExpenseItemsForm from '../Shared/ExpenseItemsForm';
import { DateTime } from 'luxon';
import {
  deleteExpenseItem,
  getIndexExpenseItems,
  postExpenseItem,
  putExpenseItem
} from '@helpers/api/tracker';
import { TrackSuccessContext } from '@contexts';
import ModalChildrenExpenseItem from '../Shared/ModalChildrenExpenseItem';
import { useNavigate } from 'react-router-dom';
import CurrencyFormat from 'react-currency-format';

export const validationSchema = Yup.object({
  amount: Yup.string()
    .test(
      'maxDigitsAfterDecimal',
      'The trust payment must be a valid number with a maximum of 2 decimal.',
      (number) => /^\d+(\.\d{1,2})?$/.test(number)
    )
    .required('This field is required'),
  day: Yup.string().required('This field is required'),
  description: Yup.string().nullable()
});

const headCells = [
  {
    id: 'amount',
    label: 'Amount'
  },
  {
    id: 'date',
    label: 'Date'
  },
  {
    id: 'description',
    label: 'Description'
  }
];

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4
};

const ExpenseItems = ({ category }) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');

  const [orderExternal, setOrderExternal] = useState('asc');
  const [orderByExternal, setOrderByExternal] = useState('');

  const [page, setPage] = useState(0);
  const [pageExternal, setPageExternal] = useState(0);

  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [rowsPerPageExternal, setRowsPerPageExternal] = useState(5);

  const [selected, setSelected] = useState([]);
  const [formValues, setFormValues] = useState({
    amount: '',
    expenseType: '',
    day: '',
    id: '',
    external: true,
    description: ''
  });
  const [dateRange, setDateRange] = useState({ startDate: null, endDate: null });
  const [openModal, setOpenModal] = useState({ open: false, breakDownItem: null });

  const { setExpenseItems, expenseItems, month } = useContext(TrackSuccessContext);

  const handleClickOpen = (values) => {
    setOpenModal({ open: true, breakDownItem: values });
  };

  const handleCloseModal = (values) => {
    setOpenModal({ open: false, breakDownItem: null });
    if (values) {
      setExpenseItems(values);
    }
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    if (dateRange.startDate !== null && dateRange.endDate !== null) {
      const fetchData = async () => {
        const result = await getIndexExpenseItems({
          params: {
            end_date: dateRange.endDate,
            start_date: dateRange.startDate
          }
        });
        setExpenseItems(result.data.expense_items);
      };
      if (dateRange.startDate !== null && dateRange.endDate !== null) {
        fetchData().catch((error) => {
          console.error(error);
        });
      }
    }
  }, [dateRange]);

  const minDate = DateTime.local().minus({ years: 7 });

  const visibleRowsExternal = useMemo(
    () =>
      stableSort(
        expenseItems?.filter((val) => val.external === true && val.category === category),
        getComparator(orderExternal, orderByExternal)
      ).slice(
        pageExternal * rowsPerPageExternal,
        pageExternal * rowsPerPageExternal + rowsPerPageExternal
      ),
    [orderExternal, orderByExternal, pageExternal, rowsPerPageExternal, expenseItems]
  );

  const visibleRows = useMemo(
    () =>
      stableSort(
        expenseItems?.filter((val) => val.external === false && val.category === category),
        getComparator(order, orderBy)
      ).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    [order, orderBy, page, rowsPerPage, expenseItems]
  );

  const handleRowClick = (row) => {
    setSelected([row.id]);

    setFormValues({
      amount: row.amount,
      expenseType: row.category,
      day: row.effective_date,
      id: row.id,
      external: true,
      description: row.description || '',
      expense_item_id: row.expense_item_id
    });
    handleOpen();
  };

  const handleFormSubmit = async (values, actions) => {
    if (selected.length > 0) {
      const result = await putExpenseItem(values.id)({
        data: {
          month: month ? DateTime.fromISO(month).toJSDate() : null,
          expense_item: {
            amount: values.amount,
            category: category,
            effective_date: values.day,
            external: values.external,
            description: values.description
          }
        }
      }).catch((error) => {
        console.error(error);
        return;
      });
      setExpenseItems(result.data);
    } else {
      const result = await postExpenseItem({
        data: {
          month: month ? DateTime.fromISO(month).toJSDate() : null,
          expense_item: {
            amount: values.amount,
            category: category,
            effective_date: values.day,
            external: values.external,
            description: values.description
          }
        }
      }).catch((error) => {
        console.error(error);
        return;
      });
      setExpenseItems(result.data);
    }
    actions.resetForm();
    resetForm();
    handleClose();
  };

  const handleDelete = async (id) => {
    const result = await deleteExpenseItem(id)({
      params: {
        month: month ? DateTime.fromISO(month).toJSDate() : null
      }
    }).catch((error) => {
      console.error(error);
      return;
    });
    resetForm();
    setExpenseItems(result.data);
    handleClose();
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const resetForm = () => {
    setSelected([]);
    setFormValues({
      amount: '',
      expenseType: '',
      day: '',
      description: ''
    });
    handleClose();
  };

  const hanldeBack = () => {
    navigate(-1);
  };

  const actionButtons = () => {
    return (
      <>
        <button className="bg-seafoam text-white px-4 py-2 rounded hover:bg-teal" type="submit">
          {selected.length > 0 ? 'Update' : 'Save'}
        </button>
        {selected.length > 0 && (
          <button
            className="bg-redDarker text-white px-4 py-2 rounded hover:bg-redDarker"
            type="button"
            onClick={() => handleDelete(formValues.id)}>
            Delete
          </button>
        )}
        <button
          className="bg-greige text-white px-4 py-2 rounded hover:bg-greige"
          type="button"
          onClick={() => resetForm()}>
          Cancel
        </button>
      </>
    );
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', my: 2 }}>
        <TableComponent
          order={order}
          setOrder={setOrder}
          orderBy={orderBy}
          setOrderBy={setOrderBy}
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          headCells={headCells}
          rows={expenseItems.filter(
            (expense) => expense.external === false && expense.category === category
          )}
          setDateRange={setDateRange}
          minDate={minDate}
          dateRange={dateRange}
          filter={true}
          title="Your reported expenses generated this month">
          <>
            {visibleRows.map((row, index) => {
              const isItemSelected = isSelected(row.id);
              return (
                <TableRow
                  hover
                  role="checkbox"
                  aria-checked={isItemSelected}
                  tabIndex={-1}
                  key={index}
                  selected={isItemSelected}
                  sx={{ cursor: 'pointer' }}>
                  <TableCell>
                    {row.id && (
                      <button
                        className="bg-cyan-500 w-56 text-white font-bold px-4 py-2 rounded hover:bg-cyan-500"
                        type="button"
                        onClick={() => handleClickOpen(row)}>
                        Breakdown Transaction
                      </button>
                    )}
                  </TableCell>
                  <TableCell align="right">
                    <CurrencyFormat
                      value={row.amount}
                      displayType="text"
                      thousandSeparator
                      decimalScale={2}
                      fixedDecimalScale
                      prefix="$"
                    />
                  </TableCell>
                  <TableCell align="right">{row.effective_date}</TableCell>
                  <TableCell align="right">{row.description}</TableCell>
                </TableRow>
              );
            })}
            {visibleRows.length === 0 && (
              <TableRow>
                <TableCell colSpan={6} align="center">
                  <Typography color="textSecondary">No data available</Typography>
                </TableCell>
              </TableRow>
            )}
          </>
        </TableComponent>
      </Paper>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <TableComponent
          order={orderExternal}
          setOrder={setOrderExternal}
          orderBy={orderByExternal}
          setOrderBy={setOrderByExternal}
          page={pageExternal}
          setPage={setPageExternal}
          rowsPerPage={rowsPerPageExternal}
          setRowsPerPage={setRowsPerPageExternal}
          headCells={headCells}
          rows={expenseItems.filter(
            (expense) => expense.external === true && expense.category === category
          )}
          setDateRange={setDateRange}
          minDate={minDate}
          dateRange={dateRange}
          filter={false}
          title="Your reported external expenses this month">
          <>
            {visibleRowsExternal.map((row, index) => {
              const isItemSelected = isSelected(row.id);
              return (
                <TableRow
                  hover
                  role="checkbox"
                  aria-checked={isItemSelected}
                  onClick={() => handleRowClick(row)}
                  tabIndex={-1}
                  key={index}
                  selected={isItemSelected}
                  sx={{ cursor: 'pointer' }}>
                  <TableCell />
                  <TableCell align="right">
                    <CurrencyFormat
                      value={row.amount}
                      displayType="text"
                      thousandSeparator
                      decimalScale={2}
                      fixedDecimalScale
                      prefix="$"
                    />
                  </TableCell>
                  <TableCell align="right">{row.effective_date}</TableCell>
                  <TableCell align="right">{row.description}</TableCell>
                </TableRow>
              );
            })}
            {visibleRowsExternal.length === 0 && (
              <TableRow>
                <TableCell colSpan={6} align="center">
                  <Typography color="textSecondary">No data available</Typography>
                </TableCell>
              </TableRow>
            )}
          </>
        </TableComponent>
      </Paper>
      <div className="flex justify-center items-center gap-4">
        <button
          className="bg-teal text-white px-4 py-2 rounded hover:bg-seafoam"
          type="button"
          onClick={() => handleOpen()}>
          + Add External Transaction
        </button>
        <button
          className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700"
          type="button"
          onClick={() => hanldeBack()}>
          Back
        </button>
      </div>
      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          <Paper className="p-8">
            <h2 className="font-bold mb-4">
              {selected.length > 0
                ? 'Update Expense Transaction'
                : 'Additional Expense Transaction'}
            </h2>
            <ExpenseItemsForm
              formValues={formValues}
              handleFormSubmit={handleFormSubmit}
              validationSchema={validationSchema}
              categorySelection={false}
              actionButtons={actionButtons}
              month={month}
            />
          </Paper>
        </Box>
      </Modal>
      {openModal.open && <ModalChildrenExpenseItem onClose={handleCloseModal} props={openModal} />}
    </Box>
  );
};

export default ExpenseItems;
