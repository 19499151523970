import React, { useState } from 'react';
import { Field, ErrorMessage } from 'formik';

import { getClass } from '@helpers/formik';

const SelectWithOptions = ({ name, errors, value = '', onChange, options }) => {
  const [inputText, setInputText] = useState(value === null ? '' : value);
  const hangleChange = (val) => {
    setInputText(val.target.value);
    onChange(val);
  };
  return (
    <div className="relative z-0 w-full max-w-lg mobile:mb-10 mb-7">
      <div className="relative z-0 group">
        <ErrorMessage
          name={name}
          component="div"
          className="mobile:hidden desktop:block tablet:block absolute -top-4 right-0 text-red font-normal text-errorMessageInput"
        />
        <Field
          as="select"
          name={name}
          value={inputText}
          className={`border px-5 py-3 border-solid border-whiteGray bg-whiteLighter rounded-md block w-full mt-0 focus:outline-none focus:ring-0 focus:border-black placeholder-darkGray selectOptions ${getClass(
            errors,
            name
          )}`}
          onChange={(val) => hangleChange(val)}>
          {options}
        </Field>
        <ErrorMessage
          name={name}
          component="div"
          className="mobile:block desktop:hidden tablet:hidden absolute top-14 left-0 text-red font-normal text-errorMessageInput"
        />
      </div>
    </div>
  );
};

export default SelectWithOptions;
