import React, { useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { users as usersApi } from '@helpers/api';

import imagesLogin from '@components/CarouselSlider/Images/imagesLogin.jsx';
import MainTemplate from '@components/MainTemplate';
import SubmitButton from '@components/SubmitButton';
import ValidatedInput from '@components/ValidatedInput';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { swalConfirmCloseConfig } from '@constants/sweetAlert';

const RequestResetPassword = () => {
  const [email, setEmail] = useState('');

  const navigate = useNavigate();
  const swalPopUp = withReactContent(Swal);

  const handleSubmit = (event) => {
    event.preventDefault();

    usersApi
      .passwordReset({
        data: {
          user: {
            email
          }
        }
      })
      .then(() => {
        swalPopUp
          .fire({
            ...swalConfirmCloseConfig,
            title: 'Check your email for a link to reset your password.'
          })
          .then(() => {
            navigate('/');
          });
      });
  };

  const FormComponent = (
    <>
      <h1 className="mobile:text-titleFormResponsive text-titleForm font-bold text-black">
        Recover your password
      </h1>
      <p className="max-w-[398px] desktop:mb-8 xl:mb-11 text-center text-darkGray text-descriptionSlide">
        Please enter the email address associated with your account and a password reset link will
        be sent to you.
      </p>
      <form onSubmit={handleSubmit} className="w-full desktop:w-4/5">
        <div className="flex flex-col justify-center items-center">
          <ValidatedInput
            placeholder="Email"
            id="email"
            type="email"
            value={email}
            onChange={setEmail}
            icon="fa-at"
            withFormik={false}
          />

          <SubmitButton value="Reset Password" />
          <button
            type="button"
            id="dashboardBtn"
            className="w-full m-auto mt-8"
            onClick={() => navigate('/')}>
            <div className="flex justify-center items-center gap-2 text-teal">
              <i className="fa-solid fa-arrow-left-to-line text-link font-bold" />
              <span className="text-link font-bold text-teal">Go back</span>
            </div>
          </button>
        </div>
      </form>
    </>
  );

  return <MainTemplate body={FormComponent} images={imagesLogin} />;
};

export default RequestResetPassword;
