import React from 'react';
import { Link } from 'react-router-dom';
import { DateTime } from 'luxon';

const SetNextMonthsGoalsButton = ({ month = DateTime.now() }) => {
  const currentDate = DateTime.now();
  const nextMonthDate = currentDate.plus({ months: 1 }).startOf('month');
  const nextMonthName = nextMonthDate.toLocaleString({ month: 'long' });
  const daysUntilNextMonth = nextMonthDate.diff(currentDate, 'days').days;
  const currentMonthsName = month.toLocaleString({ month: 'long' });

  if (daysUntilNextMonth > 15) {
    return <span>{currentMonthsName}'s Expense Tracker</span>;
  }

  return (
    <div className="inline-block">
      <Link to="/dashboard/tracker/monthly-goals" state={{ month: nextMonthDate.toJSDate() }} className="px-2 py-1 text-white bg-blue-500 rounded hover:bg-blue-600">
        Set {nextMonthName}'s Goals
      </Link>
    </div>
  );
};

export default SetNextMonthsGoalsButton;
