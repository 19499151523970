import React from 'react';
import { Box } from '@mui/material';
import { TrackerAvatarImg } from '@constants/images';

const BudgetingBuddyComponent = ({copy = null}) => {
  return (
    <Box display="flex" flexDirection="column" alignItems="center" mb={4}>
      <img
        src={TrackerAvatarImg}
        alt="Budgeting Buddy"
        className="rounded-full"
        style={{ width: 125, height: 'auto' }}
      />
      {copy && copy()}
    </Box>
  );
};

export default BudgetingBuddyComponent;
