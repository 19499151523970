import React, { memo } from 'react';
import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';
import { VoidImg, YourSuccessLogoImg } from '@constants/images';
import { Header, Body, Footer } from './PdfSections/Check';
import { Details } from './PdfSections/Details';
import { Authorization } from './PdfSections/Authorization';

const PayrollPdf = ({ payrollOptions, account }) => {
  const styles = StyleSheet.create({
    page: {
      paddingTop: 35,
      paddingBottom: 35,
      paddingHorizontal: 30,
      color: '#0A1307',
      backgroundColor: '#FFF'
    },
    logo: {
      width: 120,
      position: 'absolute',
      top: 20,
      right: 20
    },
    title: {
      textAlign: 'center',
      fontFamily: 'Montserrat',
      fontWeight: 'bold',
      fontSize: '20px',
      marginTop: 50
    },
    text: {
      margin: '10px 92px 0 92px',
      fontFamily: 'Montserrat',
      fontWeight: 'medium',
      fontSize: '12px',
      textAlign: 'center',

      inner: {
        fontFamily: 'Montserrat',
        fontWeight: 'bold',
        color: '#FF8372'
      }
    },
    secondText: {
      margin: '10px 0px 0 0px',
      fontFamily: 'Montserrat',
      fontWeight: 'medium',
      fontSize: '12px',
      textAlign: 'center'
    },
    box: {
      border: '3px solid #7693A8',
      width: '492px',
      margin: '20px auto 0 auto',
      backgroundColor: '#F4F1E9'
    },

    boxBackground: {
      position: 'absolute',
      minWidth: '100%',
      minHeight: '100%',
      display: 'block',
      height: '100%',
      width: '100%',
      opacity: 0.5
    },

    tableContainer: {
      flexDirection: 'row',
      flexWrap: 'wrap'
    },

    containerNumbers: {
      flexDirection: 'row',
      alignItems: 'center',
      textAlign: 'center',
      flexGrow: 1,
      padding: '5px 30px'
    },
    information: {
      width: '50%',
      textAlign: 'left'
    },
    information2: {
      width: '50%',
      textAlign: 'right'
    },
    numbers: {
      fontFamily: 'Montserrat',
      fontSize: '12px',
      fontWeight: 'light'
    },
    containerAuthorization: {
      border: '1px solid #000000',
      width: '500px',
      margin: '0px auto 0 auto'
    },
    disclaimer: {
      marginTop: '10px',
      marginLeft: '10px',
      fontFamily: 'Montserrat',
      fontSize: '8px',
      fontWeight: 'light'
    }
  });

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <Image src={YourSuccessLogoImg} style={styles.logo} />
        <Text style={styles.title}>DIRECT DEPOSIT AUTHORIZATION FORM</Text>
        <Text style={styles.text}>
          For Employer/HR: Please deposit{' '}
          <Text style={styles.text.inner}>
            {payrollOptions.type == 'amount'
              ? `$${payrollOptions.amount || 0}`
              : `${payrollOptions.percentage || 100}%`}
          </Text>{' '}
          to the routing and account numbers on the check below
        </Text>
        <Text style={styles.secondText}>
          The remaining net payroll should be deposited into my existing bank account.
        </Text>
        <Text style={styles.secondText}>
          Employee Name: {account.first_name} {account.last_name}
        </Text>
        <View style={styles.box}>
          <Image src={VoidImg} style={styles.boxBackground} />
          <View style={styles.tableContainer}>
            <Header account={account} />
            <Body />
            <Footer accountNumber={account.galileo_payment_reference_number} />
          </View>
        </View>
        <View>
          <View style={styles.containerNumbers}>
            <View style={styles.information}>
              <Text style={styles.numbers}>Routing Number</Text>
            </View>
            <View style={styles.information2}>
              <Text style={styles.numbers}>Bank Account Number</Text>
            </View>
          </View>
        </View>
        <View>
          <Details account={account} />
        </View>
        <View style={styles.containerAuthorization}>
          <Authorization payrollOptions={payrollOptions} account={account} />
        </View>
        <Text style={styles.disclaimer}>
          YourSuccess Bank Account is a demand deposit account established by, and the YourSuccess Debit Card is
          issued by, Pathward, N.A., Member FDIC. Mastercard® and the circles design are registered
          trademarks of Mastercard International Incorporated. Card is only valid for use in the U.S.
        </Text>
      </Page>
    </Document>
  );
};

export default memo(PayrollPdf);
