import React from 'react';
import { NavLink, useParams } from 'react-router-dom';
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import { routerTracker } from '../types/breadcrumbs';
import { EXPENSE_TYPE } from '@utilities/constants';

const BreadCrumbComponent = () => {
  const breadcrumbs = useBreadcrumbs(routerTracker);
  const params = useParams();

  return (
    <div>
      <div className="flex items-center justify-start w-full gap-8">
        <NavLink key="/dashboard/tracker" to="/dashboard/tracker/track-success">
          <div
            className={`${
              breadcrumbs[0].location.pathname === '/dashboard/tracker/track-success'
                ? 'text-purple-600 border-purple-600 border-b-2'
                : 'text-darkGray border-darkGray'
            } font-semibold`}>
            Tracker Dashboard
          </div>
        </NavLink>
        <NavLink key="/dashboard/tracker/expenses" to="/dashboard/tracker/expenses">
          <div
            className={`${
              breadcrumbs[0].location.pathname === '/dashboard/tracker/expenses'
                ? 'text-purple-600 border-purple-600 border-b-2'
                : 'text-darkGray border-darkGray'
            } font-semibold`}>
            Expense Overview
          </div>
        </NavLink>
        {params.category && (
          <NavLink
            key={`/dashboard/tracker/expense-item-by-category/${params.category}`}
            to={`/dashboard/tracker/expense-item-by-category/${params.category}`}>
            <div
              className={`${
                breadcrumbs[0].location.pathname ===
                `/dashboard/tracker/expense-item-by-category/${params.category}`
                  ? 'text-purple-600 border-purple-600 border-b-2'
                  : 'text-darkGray border-darkGray'
              } font-semibold`}>
              Category: {EXPENSE_TYPE.find((category) => category.value === params.category)?.label}
            </div>
          </NavLink>
        )}
        <NavLink key="/dashboard/tracker/trustee-payment" to="/dashboard/tracker/trustee-payment">
          <div
            className={`${
              breadcrumbs[0].location.pathname === '/dashboard/tracker/trustee-payment'
                ? 'text-purple-600 border-purple-600 border-b-2'
                : 'text-darkGray border-darkGray'
            } font-semibold`}>
            Trustee Payment
          </div>
        </NavLink>
        <NavLink key="/dashboard/tracker/income-goals" to="/dashboard/tracker/income-goals">
          <div
            className={`${
              breadcrumbs[0].location.pathname === '/dashboard/tracker/income-goals'
                ? 'text-purple-600 border-purple-600 border-b-2'
                : 'text-darkGray border-darkGray'
            } font-semibold`}>
            Income Goals
          </div>
        </NavLink>
      </div>
    </div>
  );
};

export default BreadCrumbComponent;
