import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Login from '@pages/Login.jsx';
import PasswordReset from '@pages/PasswordReset';
import RequestResetPassword from '@pages/RequestResetPassword';
import Dashboard from '@pages/Dashboard.jsx';
import EnrollmentFunding from '@pages/EnrollmentFunding.jsx';
import Closed from '@pages/Closed.jsx';
import FraudAlerts from '@pages/FraudAlerts.jsx';
import NotFound from '@pages/NotFound.jsx';

import PrivateRoute from './PrivateRoute';
import SessionRoute from './SessionRoute';
import AccountApplicationRoute from './AccountApplicationRoute';

const ApplicationRoutes = () => {
  return (
    <Router>
      <Routes>
        <Route
          path="/request-reset-password"
          element={
            <SessionRoute>
              <RequestResetPassword />
            </SessionRoute>
          }
        />
        <Route
          path="/password-reset"
          element={
            <SessionRoute>
              <PasswordReset />
            </SessionRoute>
          }
        />
        <Route
          path="/"
          element={
            <SessionRoute>
              <Login />
            </SessionRoute>
          }
        />
        <Route
          path="/enrollment-funding/*"
          element={
            <PrivateRoute>
              <EnrollmentFunding />
            </PrivateRoute>
          }
        />

        <Route
          path="/dashboard/*"
          element={
            <PrivateRoute>
              <Dashboard />
            </PrivateRoute>
          }
        />

        <Route
          path="/fraud-alerts/:authDenialId"
          element={
            <PrivateRoute>
              <FraudAlerts />
            </PrivateRoute>
          }
        />

        <Route
          path="/closed"
          element={
            <PrivateRoute>
              <Closed />
            </PrivateRoute>
          }
        />

        <Route path="/account-application/*" element={<AccountApplicationRoute />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
};

export default ApplicationRoutes;
