import React from 'react';

const PatriotActCopy = () => {
  return (
    <p className="desktop:w-2/4 tablet:w-3/4 mobile:w-10/12 bg-whiteLighter p-7 rounded-3xl my-6">
      Important information for opening an Account: To help the federal government fight the funding
      of terrorism and money laundering activities, the USA PATRIOT Act requires all financial
      institutions and their third parties to obtain, verify, and record information that identifies
      each person who opens an Account. What this means for you: When you open an account, we will
      ask for your name, address, date of birth, and other information that will allow us to
      identify you. We may also ask to see a copy of your driver&apos;s license or other identifying
      documents.
    </p>
  );
};

export default PatriotActCopy;
