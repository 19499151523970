/* eslint-disable react/no-unescaped-entities */
import React, { useState, useEffect, useContext, useRef } from 'react';
import { Formik, Form } from 'formik';
import { Link, useNavigate, useParams, useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import * as Yup from 'yup';
import FormTitle from '@components/FormTitle';
import FormSubtitle from '@components/FormSubtitle';
import LinkButton from '@components/LinkButton';

import { EnrollmentContext } from '@contexts';

import { achAccounts as achAccountsApi, achTransactions as achTransactionsApi } from '@helpers/api';

import ErrorsTemplate from '@components/ErrorsTemplate';
import Loader from '@components/Loader';
import ButtonsFormMiddleSteps from '@components/ButtonsFormMiddleSteps';
import CurrencyInput from '@components/CurrencyInput';
import CurrencyFormat from 'react-currency-format';
import AchAccountItem from './AchAccountItem';

export const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop);

export const getWindowSize = () => {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
};

const AchTransactionForm = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const lastIndex = pathname.lastIndexOf('/ach/create-transaction');
  const baseRoute = pathname.slice(0, lastIndex);
  const ref = useRef(null);

  const { achAccountId } = useParams();
  const [isLoading, setLoading] = useState(false);
  const [errorsState, setErrors] = useState(null);
  const [currentAchAccount, setCurrentAchAccount] = useState(null);
  const [achAccounts, setAchAccounts] = useState(null);
  const [windowSize, setWindowSize] = useState(getWindowSize());

  const { enrollment } = useContext(EnrollmentContext);
  const swalPopUp = withReactContent(Swal);

  useEffect(() => {
    setLoading(true);
    if (achAccountId) {
      achAccountsApi
        .getById(achAccountId)
        .then((response) => {
          setCurrentAchAccount(response.data);
        })
        .catch((errorApi) => {
          setErrors(errorApi.response);
        })
        .finally(() => setLoading(false));
    } else {
      achAccountsApi
        .getShow()
        .then((response) => {
          if (response.data.length > 0) {
            setAchAccounts(response.data);
          } else {
            navigate(
              !enrollment ? '/dashboard/funding/plaid/link' : '/enrollment-funding/plaid/link'
            );
          }
        })
        .catch((errorApi) => {
          setErrors(errorApi.response);
        })
        .finally(() => setLoading(false));
    }
  }, []);

  useEffect(() => {
    if (currentAchAccount && !achAccountId && windowSize.innerWidth < 414) {
      scrollToRef(ref);
    }
  }, [currentAchAccount]);

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  const handleSubmit = (values) => {
    setLoading(true);
    achTransactionsApi
      .post({
        data: {
          ach_transaction: {
            ...values,
            achAccountId: currentAchAccount.galileo_ach_account_id,
            ach_account_id: currentAchAccount.id
          }
        }
      })
      .then(() => {
        navigate(`${baseRoute}/ach/create-transaction/confirmation`);
      })
      .catch((errorsApi) => {
        if (errorsApi.response.status === 500) {
          if (errorsApi.response.data.errors) {
            setErrors(errorsApi.response.data.errors);
          } else {
            setErrors({ Error: errorsApi.response.statusText });
          }
        } else if (errorsApi.response.status === 429) {
          swalPopUp.fire({
            position: 'center',
            icon: 'warning',
            title: 'You have exceeded your transaction velocity limit. Please try again later.',
            showConfirmButton: true
          });
        }
        setLoading(false);
      });
  };

  const formActionButtons = (isSubmitting) => (
    <>
      {enrollment && (
        <ButtonsFormMiddleSteps
          isSubmitting={isSubmitting}
          linkGoBack="/enrollment-funding"
          textForward="Submit Transaction >"
          linkDashboard
        />
      )}
      {!enrollment && (
        <input
          type="submit"
          value="Submit Transaction"
          className={`tablet:w-[315px] desktop:w-[244px] ${
            isSubmitting || !currentAchAccount
              ? 'bg-greyBlueDisabled'
              : 'bg-greyblue hover:bg-greyblueHover'
          } rounded-xl h-14 text-descriptionSlide text-white font-bold cursor-pointer mt-7`}
          disabled={isSubmitting || !currentAchAccount}
        />
      )}
      {achAccountId && (
        <input
          type="button"
          value="Link a bank account"
          className="tablet:w-[315px] desktop:w-[244px] bg-seafoam hover:bg-teal rounded-xl h-14 text-descriptionSlide text-white font-bold cursor-pointer mt-7"
          onClick={() =>
            navigate(
              !enrollment ? '/dashboard/funding/plaid/link' : '/enrollment-funding/plaid/link'
            )
          }
        />
      )}
    </>
  );

  const verificationInstructions = (achAccount) => {
    switch (achAccount.verification_status) {
      case 'pending_automatic_verification':
        return 'We are currently verifying this account. Please check back later to initiate transactions using this account.';
        break;
      case 'verification_failed':
        return 'Verification for this account has failed. Please try linking a new bank account.';
        break;
      default:
        return 'Uh Oh! Something went wrong while verifying this account. Please try linking a new bank account.';
        break;
    }
  };

  const handleClickListAccounts = (achAccount) => {
    if (achAccount.verified) {
      setCurrentAchAccount(achAccount);
    } else if (achAccount.verification_status === "pending_manual_verification") {
      navigate(`${baseRoute}/plaid/link/${achAccount.id}`)
    } else {
      swalPopUp.fire({
        position: 'center',
        icon: 'warning',
        title: verificationInstructions(achAccount),
        showConfirmButton: true
      });
    }
  };

  if ((achAccountId !== undefined && !currentAchAccount) || (!achAccountId && !achAccounts) || isLoading) {
    return <Loader />;
  }

  const body = (
    <>
      {!!errorsState && <ErrorsTemplate errors={errorsState} />}
      <div className="w-full mt-4" id="addTransaction">
        <Formik
          initialValues={{
            amount: '',
            amountConfirmation: ''
          }}
          validationSchema={Yup.object({
            amount: Yup.number()
              .test(
                'maxDigitsAfterDecimal',
                'amount can only have up to two digits after the decimal',
                (number) => /^\d+(\.\d{1,2})?$/.test(number)
              )
              .max(10000, 'Amount must be less than or equal to $10.000,00'),
            amountConfirmation: Yup.number()
              .oneOf([Yup.ref('amount'), null], "Amount Confirmation doesn't match")
              .test(
                'maxDigitsAfterDecimal',
                'amount can only have up to two digits after the decimal',
                (number) => /^\d+(\.\d{1,2})?$/.test(number)
              )
              .max(10000, 'Amount confirmation must be less than or equal to $10.000,00')
          })}
          onSubmit={handleSubmit}>
          {({ isSubmitting, values, errors, setFieldValue }) => (
            <Form className="flex flex-col justify-center items-center">
              <CurrencyInput
                value={values.amount}
                handleChange={setFieldValue}
                name="amount"
                placeholder="Amount"
                errors={errors}
              />
              <CurrencyInput
                value={values.amountConfirmation}
                handleChange={setFieldValue}
                name="amountConfirmation"
                placeholder="Amount Confirmation"
                errors={errors}
              />
              <div className="text-center text-descriptionSlide text-darkGray font-normal">
                By clicking on{' '}
                <span className="font-bold text-greyblue">&apos;Submit Transaction&apos;</span> you
                agree that{' '}
                <span className="text-red font-bold">
                  <CurrencyFormat
                    value={values.amount || 0}
                    displayType="text"
                    thousandSeparator
                    prefix="$"
                  />
                </span>{' '}
                will be deducted from your external bank account.
              </div>
              {formActionButtons(isSubmitting)}
            </Form>
          )}
        </Formik>
      </div>
    </>
  );

  return (
    <div className="flex desktop:flex-row tablet:flex-col mobile:flex-col justify-center items-center gap-10">
      {!achAccountId && (
        <>
          <div className="shadow-achShadow rounded-3xl desktop:px-8 desktop:pb-8 desktop:pt-10 tablet:px-8 tablet:pb-8 tablet:pt-10 mobile:px-2 mobile:pb-8 mobile:pt-10 bg-white flex flex-col justify-between items-center w-full">
            <FormTitle>Your ACH Accounts</FormTitle>
            <div className="flex flex-col justify-center items-center gap-4">
              <LinkButton path={`${baseRoute}/ach/transactions-list`}>
                <span>View all transactions</span>
                <i className="fa-solid fa-arrow-right-long" />
              </LinkButton>
              <input
                type="button"
                value="Link a bank account"
                className="tablet:w-[315px] desktop:w-[244px] bg-seafoam hover:bg-teal rounded-xl h-14 text-descriptionSlide text-white font-bold cursor-pointer mt-7"
                onClick={() => navigate(`${baseRoute}/plaid/link`)}
              />

              {achAccounts.map((achAccount) => (
                <AchAccountItem
                  key={achAccount.id}
                  achAccount={achAccount}
                  currentAchAccount={currentAchAccount}
                  handleClickListAccounts={handleClickListAccounts}
                />
              ))}
              {!achAccounts && (
                <p className="p-4 text-center font-bold">
                  You don't have accounts to show, please link a Bank Account
                </p>
              )}
              <Link to={baseRoute} className="block">
                <div className="flex justify-center items-center gap-2 text-teal font-bold">
                  <i className="fa-solid fa-arrow-left-long" />
                  <span>Back to funding options</span>
                </div>
              </Link>
            </div>
          </div>
          <div className={`w-full ${!currentAchAccount ? 'block' : 'hidden'}`}>&nbsp;</div>
        </>
      )}
      <div
        className={`max-w-xl w-full shadow-achShadow rounded-3xl desktop:px-8 desktop:pb-8 desktop:pt-10 tablet:px-8 tablet:pb-8 tablet:pt-10 mobile:px-2 mobile:pb-8 mobile:pt-10  bg-white ${
          currentAchAccount ? 'block' : 'hidden'
        }`}
        ref={ref}>
        <FormTitle>Create a transaction</FormTitle>
        {currentAchAccount && (
          <FormSubtitle>
            You are creating a transaction to pull from your external bank account named{' '}
            {currentAchAccount.name}
          </FormSubtitle>
        )}
        {body}
      </div>
    </div>
  );
};

export default AchTransactionForm;
