import React, { useState, useEffect, useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { Font, BlobProvider } from '@react-pdf/renderer';
import * as Yup from 'yup';
import ErrorsTemplate from '@components/ErrorsTemplate';
import ValidatedInput from '@components/ValidatedInput';
import AmountInput from '@components/AmountInput';
import FormTitle from '@components/FormTitle';
import AchToggle from '@components/AchToggle';
import { ROUTING_NUMBER } from '@utilities/constants';
import { getClass } from '@helpers/formik';
import { accounts as accountsApi } from '@helpers/api';
import { EnrollmentContext } from '@contexts';
import {
  MontserratBold,
  MontserratMedium,
  MontserratRegular,
  MontserratLight,
  DancingScriptBold
} from '@constants/fonts';
import PayrollPdf from './PayrollPdf';

const PayrollForm = () => {
  const [account, setAccount] = useState({
    first_name: '',
    last_name: '',
    address: { address_1: '', city: '', state: '', postal_code: '' },
    galileo_payment_reference_number: ''
  });
  const [errors, setErrors] = useState(null);
  const [hideAccNumber, setHideAccNumber] = useState(true);
  const [payrollOptions, setPayrollOptions] = useState({
    amount: '',
    percentage: 100,
    type: 'percentage',
  });

  const { enrollment } = useContext(EnrollmentContext);

  const { pathname } = useLocation();
  const lastIndex = pathname.lastIndexOf('/');
  const before = pathname.slice(0, lastIndex);

  useEffect(() => {
    accountsApi
      .getShow()
      .then((response) => {
        setAccount({ ...response.data });
      })
      .catch((errorsApi) => {
        setErrors(errorsApi);
      });
  }, []);

  const handleValueChange = (event) => {
    const { name, value } = event.target;
    setPayrollOptions({ ...payrollOptions, type: name, [name]: value });
  };

  const formValid = (values, yupErrors) =>
    (values.type === 'percentage' && !yupErrors.percentage) ||
    (values.type === 'amount' && !yupErrors.amount);

  Font.register({
    family: 'Montserrat',
    fonts: [
      {
        src: MontserratBold,
        fontWeight: 'bold'
      },
      {
        src: MontserratMedium,
        fontWeight: 'medium'
      },
      {
        src: MontserratRegular,
        fontWeight: 'normal'
      },
      {
        src: MontserratLight,
        fontWeight: 'light'
      }
    ]
  });

  Font.register({
    family: 'Dancing Script',
    src: DancingScriptBold
  });

  const body = (
    <div className="w-full flex flex-col justify-start items-center">
      <Formik
        initialValues={{ ...payrollOptions }}
        validationSchema={Yup.object({
          amount: Yup.number()
            .min(1, 'Minimun value is 1')
            .required('Amount is required')
            .nullable(),
          percentage: Yup.number()
            .min(1, 'Minimum value is 1')
            .max(100, 'Max is 100 percent')
            .required('Percentage is required')
            .nullable()
        })}>
        {({ handleChange, values, setFieldValue, errors: yupErrors }) => (
          <Form className="w-full flex flex-col gap-7 justify-center items-center">
            <div className="flex justify-center items-center gap-20" role="group">
              <label>
                <Field
                  type="radio"
                  name="type"
                  value="percentage"
                  checked={payrollOptions.type === 'percentage'}
                  onChange={(e) => {
                    handleChange(e);
                    setPayrollOptions({ ...payrollOptions, type: 'percentage' });
                  }}
                />
                % Percentage
              </label>
              <label>
                <Field
                  type="radio"
                  name="type"
                  value="amount"
                  checked={payrollOptions.type === 'amount'}
                  onChange={(e) => {
                    handleChange(e);
                    setPayrollOptions({ ...payrollOptions, type: 'amount' });
                  }}
                />
                Amount
              </label>
              <ErrorMessage name="type" component="div" />
            </div>
            {values.type === 'amount' ? (
              <div className="relative z-0 w-full max-w-lg mobile:mb-10 mb-7">
                <div className="relative z-0 group">
                  <ErrorMessage
                    name="amount"
                    component="div"
                    className="mobile:hidden desktop:block tablet:block absolute -top-4 right-0 text-red font-normal text-errorMessageInput"
                  />
                  <Field
                    name="amount"
                    placeholder="Amount of transfer"
                    component={AmountInput}
                    value={values.amount}
                    onChange={(e) => handleChange(e.target.value.replace(',', ''))}
                    onValueChange={(val) => {
                      handleValueChange({ target: { name: 'amount', value: val.formattedValue } });
                      setFieldValue('amount', val.floatValue);
                    }}
                    className={`border px-5 py-3 border-solid border-whiteGray bg-whiteLighter rounded-md block w-full mt-0 appearance-none focus:outline-none focus:ring-0 focus:border-black placeholder-darkGray ${getClass(
                      errors,
                      'amount'
                    )}`}
                  />
                  <label
                    htmlFor="amount"
                    className="absolute duration-300 top-3 -z-1 origin-0 text-black left-0">
                    Amount of transfer
                  </label>
                  <ErrorMessage
                    name="amount"
                    component="div"
                    className="mobile:block desktop:hidden tablet:hidden absolute top-14 left-0 text-red font-normal text-errorMessageInput"
                  />
                </div>
              </div>
            ) : null}

            {values.type === 'percentage' ? (
              <ValidatedInput
                errors={errors}
                name="percentage"
                placeholder="Percentage of transfer"
                type="text"
                onChange={handleChange}
                onKeyUp={handleValueChange}
                value={values.percentage}
              />
            ) : null}

            <div className="flex flex-col justify-start items-start">
              <div className="text-darkGray text-link">
                <label htmlFor="reference_number" aria-label="Account Number">
                  Account Number
                </label>
                <span className="ml-4">
                  <input
                    type={hideAccNumber ? 'password' : 'text'}
                    className="p-1 w-36"
                    value={account.galileo_payment_reference_number}
                    disabled
                    id="reference_number"
                  />
                </span>
                <button
                  type="button"
                  onClick={() => setHideAccNumber(!hideAccNumber)}
                  className="w-14 inline-flex text-center px-2.5 py-1.5 text-xs font-medium rounded text-indigo-700 bg-indigo-100 hover:bg-indigo-200 ml-4">
                  {hideAccNumber ? 'Show' : 'Hide'}
                </button>
              </div>
              <div className="text-darkGray text-link">
                Routing Number: <span className="ml-4">{ROUTING_NUMBER}</span>
              </div>
            </div>

            {!enrollment ? <AchToggle /> : null}
            {enrollment ? <AchToggle accountEnrollment={account} setAccount={setAccount} /> : null}

            {formValid(values, yupErrors) && account ? (
              <BlobProvider
                document={<PayrollPdf payrollOptions={payrollOptions} account={account} />}>
                {({ url }) => (
                  <a
                    href={url}
                    target="_blank"
                    className="tablet:w-[315px] desktop:w-[315px] bg-teal hover:bg-seafoam rounded-xl h-14 text-descriptionSlide text-white font-bold cursor-pointer flex justify-center items-center gap-4"
                    rel="noreferrer">
                    <span>View Direct Deposit Form</span>
                  </a>
                )}
              </BlobProvider>
            ) : (
              <a className="tablet:w-[315px] desktop:w-[315px] bg-whiteGray rounded-xl h-14 text-descriptionSlide text-white font-bold flex justify-center items-center gap-4">
                <span>View Direct Deposit Form</span>
              </a>
            )}
          </Form>
        )}
      </Formik>
    </div>
  );

  return (
    <>
      {!!errors ? <ErrorsTemplate errors={errors} /> : null}
      <div className="flex desktop:flex-row tablet:flex-col desktop:w-1/2 tablet:w-full mobile:w-full gap-14 justify-center items-center">
        <div className="w-full shadow-achShadow rounded-3xl desktop:px-8 tablet:px-6 mobile:px-3 pb-8 pt-10 bg-white">
          <FormTitle>Direct Deposit</FormTitle>
          {body}
        </div>
      </div>
      <Link to={before} className="block">
        <div className="flex justify-center items-center gap-2 text-teal font-bold">
          <i className="fa-solid fa-arrow-left-long" />
          <span>Back to funding options</span>
        </div>
      </Link>
    </>
  );
};

export default PayrollForm;
