import React from 'react';
import { useNavigate } from 'react-router-dom';
import { SignOut } from '../../helpers/SignOut';

const SignOutButton = ({ isFunding = false }) => {
  const navigate = useNavigate();

  const returnDashboard = () => {
    navigate('/dashboard');
  };

  return (
    <>
      <button type="button" className="w-full m-auto mt-8" onClick={() => SignOut()}>
        <div className="flex justify-center items-center gap-2 text-seafoam">
          <i className="fa-solid fa-arrow-right-from-bracket text-link font-bold" />
          <span className="text-link font-bold">Log Out</span>
        </div>
      </button>
      {isFunding && (
        <button
          type="button"
          id="dashboardBtn"
          className="w-full m-auto mt-8"
          onClick={() => returnDashboard()}>
          <div className="flex justify-center items-center gap-2 text-seafoam">
            <i className="fa-solid fa-grid-horizontal text-link font-bold" />
            <span className="text-link font-bold">Take Me to My Dashboard</span>
          </div>
        </button>
      )}
    </>
  );
};

export default SignOutButton;
