import PatriotActCopy from '@components/PatriotActCopy';
import PhoneNumberInput from '@components/PhoneNumberInput';
import SelectWithOptions from '@components/SelectWithOptions';
import SignOutButton from '@components/SignOutButton';
import ValidatedInput from '@components/ValidatedInput';
import { useOccupations } from '@hooks';
import { Form, Formik } from 'formik';
import { DateTime } from 'luxon';
import React, { useEffect } from 'react';
import * as Yup from 'yup';
import FieldDatePicker from '../../components/DatePickerInput';
import FormTitle from './FormTitle';

const OccupationOptions = ({ occupations }) => (
  <>
    <option value="" disabled>
      Occupation
    </option>
    {occupations.map(({ code, definition }) => {
      return (
        <option key={code} value={code}>
          {definition}
        </option>
      );
    })}
  </>
);

const DepositAmountOptions = () => (
  <>
    <option value="" disabled>
      Expected Monthly Deposit Amount
    </option>
    <option value="1">2,000 or less</option>
    <option value="2">More than $2,000 and less than $10,000</option>
    <option value="3">$10,001 or more but less than $20,000</option>
    <option value="4">$20,000 or more</option>
  </>
);

const eighteenYearsAgo = DateTime.now().minus({ years: 18 });
const oneHundredTwentyFiveYearsAgo = DateTime.now().minus({ years: 125 });
const validationSchema = Yup.object({
  primary_phone: Yup.string()
    .required('Required')
    .nullable()
    .length(10, 'Please enter a complete phone number'),
  date_of_birth: Yup.date()
    .max(eighteenYearsAgo, 'You must be at least 18 years old')
    .min(oneHundredTwentyFiveYearsAgo, 'You must be younger than 125')
    .required('Required')
    .nullable(),
  income_source: Yup.string()
    .test('is-selected', 'Expected Monthly Deposit Amount', (value) => value !== null)
    .required('Required'),
  occupation: Yup.string()
    .test('is-selected', 'Occupation', (value) => value !== null)
    .required('Required')
});

const PersonalInformationForm = ({ accountApplication, handleSubmit, setCurrentStep }) => {
  const { occupations } = useOccupations();

  useEffect(() => {
    setCurrentStep('personal_information');
  }, []);

  const formatDateOfBirth = (values) => {
    if (typeof(values.date_of_birth) === "string") {
      return values
    };

    return ({
      ...values,
      date_of_birth: values.date_of_birth.toISODate()
    })
  }

  return (
    <>
      <FormTitle>Personal Information</FormTitle>
      <Formik
        initialValues={accountApplication}
        validationSchema={validationSchema}
        validateOnBlur
        validateOnMount
        validateOnChange
        onSubmit={(values, { setSubmitting }) => {
          handleSubmit(formatDateOfBirth(values), 'address');
          setSubmitting(false);
        }}>
        {({ isSubmitting, values, errors, handleChange }) => (
          <Form className="flex flex-col items-center justify-center w-full">
            <ValidatedInput
              type="text"
              name="first_name"
              placeholder="First Name"
              errors={errors}
              value={values.first_name}
              onChange={handleChange}
              disabled={true}
            />
            <ValidatedInput
              type="text"
              name="middle_name"
              placeholder="Middle Name"
              errors={errors}
              value={values.middle_name}
              onChange={handleChange}
              disabled={true}
            />
            <ValidatedInput
              type="text"
              name="last_name"
              placeholder="Last Name"
              errors={errors}
              value={values.last_name}
              onChange={handleChange}
              disabled={true}
            />
            <PhoneNumberInput
              name="primary_phone"
              placeholder="Phone Number"
              type="text"
              errors={errors}
            />

            <FieldDatePicker name="date_of_birth" placeholder="Date of Birth" errors={errors} openTo="year" />

            <SelectWithOptions
              defaultValue=""
              errors={errors}
              name="income_source"
              onChange={handleChange}
              options={<DepositAmountOptions />}
              placeholder="Expected Monthly Deposit Amount"
              value={values.income_source}
            />

            <SelectWithOptions
              defaultValue=""
              errors={errors}
              name="occupation"
              onChange={handleChange}
              options={<OccupationOptions occupations={occupations} />}
              placeholder="Occupation"
              value={values.occupation}
            />

            <input
              type="submit"
              value="Next >"
              className="tablet:w-[315px] desktop:w-[500px] bg-greyblue hover:bg-greyblueHover rounded-xl h-14 text-descriptionSlide text-white font-bold cursor-pointer mt-7"
              disabled={isSubmitting}
            />
          </Form>
        )}
      </Formik>
      <SignOutButton />
      <PatriotActCopy />
    </>
  );
};

export default PersonalInformationForm;
