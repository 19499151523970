import React, { useState } from 'react';
import Footer from '@components/Footer';
import { FundingRoutes } from '@components/Funding';
import Loader from '@components/Loader';
import { EnrollmentContext } from '@contexts';
import { YourSuccessLogoImg, FundingBackgroundImg } from '@constants/images';

const styles = {
  backgroundImage: `url(${FundingBackgroundImg})`
};

const EnrollmentFunding = () => {
  const [loading, setLoading] = useState(false);
  const navDescription = `We provide multiple ways to add money to your bank account`;

  if (loading) {
    return <Loader />;
  }

  return (
    <EnrollmentContext.Provider value={{ enrollment: true }}>
      <div
        className="flex flex-col justify-between items-center w-full min-h-screen bg-backgrounWhite pt-10 pb-7 gap-7 px-4"
        style={styles}>
        <img src={YourSuccessLogoImg} alt="YourSuccess logo" className="w-[227px] h-[77px] mb-12" />
        <FundingRoutes
          setLoading={setLoading}
          navTitle="Congratulations!"
          navDescription={navDescription}
        />
        <Footer isNotMain />
      </div>
    </EnrollmentContext.Provider>
  );
};

export default EnrollmentFunding;
