import React from 'react';
import { Typography, Box, Paper } from '@mui/material';
import * as Yup from 'yup';
import { DateTime } from 'luxon';
import Swal from 'sweetalert2';
import { TrackerAvatarImg } from '@constants/images';
import { postExpenseItem } from '@helpers/api/tracker';
import ExpenseItemsForm from '../Shared/ExpenseItemsForm';

const ExternalExpenses = ({ setExpenseItems, month }) => {
  const validationSchema = Yup.object({
    amount: Yup.string()
      .test(
        'maxDigitsAfterDecimal',
        'The trust payment must be a valid number with a maximum of 2 decimal.',
        (number) => /^\d+(\.\d{1,2})?$/.test(number)
      )
      .required('This field is required'),
    day: Yup.string().required('This field is required'),
    description: Yup.string().nullable(),
    category: Yup.string().required('This field is required')
  });

  const formValues = {
    amount: '',
    day: '',
    external: true,
    description: '',
    category: ''
  };

  const handleFormAlert = (success) => {
    const successMessage = 'Your operation was completed successfully.';
    const errorMessage = 'Something went wrong. Please try again.';
    Swal.fire({
      title: success ? 'Success!' : 'Error!',
      text: success ? successMessage : errorMessage,
      icon: success ? 'success' : 'error',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true
    });
  };

  const handleFormSubmit = async (values, { resetForm }) => {
    try {
      const result = await postExpenseItem({
        data: {
          month: month ? DateTime.fromISO(month).toJSDate() : null,
          expense_item: {
            amount: values.amount,
            category: values.category,
            effective_date: values.day,
            external: values.external,
            description: values.description
          }
        }
      });
      setExpenseItems(result.data);
      handleFormAlert(true);
      resetForm();
    } catch (error) {
      handleFormAlert(false);
    }
  };

  const actionButtons = () => {
    return (
      <>
        <button
          className="bg-seafoam text-white px-4 py-2 rounded hover:bg-teal mb-8"
          style={{ marginTop: 2 }}
          type="submit">
          + Save external transaction
        </button>
      </>
    );
  };

  return (
    <>
      <h1 className="title-settings text-dashBoardTopName font-bold text-black text-center">
        External Spending
      </h1>
      <Paper elevation={1} sx={{ p: 3 }}>
        <Box display="flex" flexDirection="row" mb={1}>
          <img
            src={TrackerAvatarImg}
            alt="Budgeting Buddy"
            className="rounded-full"
            style={{ width: 50, height: 50 }}
          />
          <Typography variant="body2" textAlign="justify" sx={{ marginLeft: 1 }}>
            Dollar Bill: "It's great to keep all of your payments in one place but we understand you
            may have spending you want to track outside of the YourSuccess Bank Account. You can add those
            transactions here and we will keep track of them for you!"
          </Typography>
        </Box>
        <Box>
          <ExpenseItemsForm
            formValues={formValues}
            handleFormSubmit={handleFormSubmit}
            validationSchema={validationSchema}
            categorySelection={true}
            actionButtons={actionButtons}
            month={month}
          />
        </Box>
      </Paper>
    </>
  );
};

export default ExternalExpenses;
