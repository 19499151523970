import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { Route, Routes, Navigate, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { accountApplications as accountApplicationsApi } from '@helpers/api';
import steps from '@constants/signup';
import { swalConfig } from '@constants/sweetAlert';

import Loader from '@components/Loader';
import ErrorsTemplate from '@components/ErrorsTemplate';
import MainTemplate from '@components/MainTemplate';
import imagesLogin from '@components/CarouselSlider/Images/imagesLogin.jsx';

import PersonalInformationForm from './PersonalInformationForm';
import AddressForm from './AddressForm';
import IdentificationForm from './IdentificationForm';
import ReviewForm from './ReviewForm';
import ReferralPage from './ReferralPage';
import RejectionPage from './RejectionPage';
import EnrollmentProgressBar from './EnrollmentProgressBar';

const AccountApplication = () => {
  const [accountApplication, setAccountApplication] = useState(null);
  const [loading, setLoading] = useState(true);
  const [currentStep, setCurrentStep] = useState(null);
  const [hasPhoneVerification, setHasPhoneVerification] = useState(false);
  const navigate = useNavigate();
  const furthestStep =
    accountApplication && accountApplication.furthest_enrollment_step.replaceAll('_', '-');
  const swalError = withReactContent(Swal);

  useEffect(() => {
    accountApplicationsApi
      .getShow()
      .then((response) => {
        setAccountApplication(response.data);
        setCurrentStep(response.data.furthest_enrollment_step);
        setHasPhoneVerification(response.data.has_phone_verification);
        setLoading(false);
      })
      .catch((errorsResponse) => {
        const errorHtml = <ErrorsTemplate errors={[errorsResponse["message"]]} />;
        swalError
          .fire({
            html: errorHtml,
            ...swalConfig
          })
          .then(() => {
            setLoading(false);
          });
      });
  }, []);

  const handleSubmit = (values, destination) => {
    setLoading(true);
    accountApplicationsApi
      .put({
        data: {
          account_application: {
            ...values,
            ...{ furthest_enrollment_step: _.snakeCase(destination) }
          }
        }
      })
      .then(() => {
        setAccountApplication(values);
        navigate(`/account-application/${destination}`);
        setLoading(false);
      })
      .catch((errorsResponse) => {
        const errorHtml = <ErrorsTemplate errors={errorsResponse.response.data.errors} />;
        swalError
          .fire({
            html: errorHtml,
            ...swalConfig
          })
          .then(() => {
            setLoading(false);
          });
      });
  };

  const navigateTo = (accountApplicationObject, element) => {
    if (accountApplicationObject.status === 'pending') {
      return element;
    }
    if (accountApplicationObject.status === 'rejected') {
      return <Navigate replace to="/account-application/application-denied" />;
    }
    if (accountApplicationObject.status === 'referred') {
      return <Navigate replace to="/account-application/application-referred" />;
    }

    return <div />;
  };

  const body = () => {
    if (loading) {
      return <Loader />;
    }
    return (
      !!accountApplication && (
        <Routes>
          <Route
            path="/"
            element={navigateTo(
              accountApplication,
              <Navigate replace to={`/account-application/${furthestStep}`} />
            )}
          />

          <Route
            path="/personal-information"
            exact
            element={navigateTo(
              accountApplication,
              <PersonalInformationForm
                handleSubmit={handleSubmit}
                accountApplication={accountApplication}
                setCurrentStep={setCurrentStep}
              />
            )}
          />

          <Route
            path="/address"
            exact
            element={navigateTo(
              accountApplication,
              <AddressForm
                handleSubmit={handleSubmit}
                accountApplication={accountApplication}
                setCurrentStep={setCurrentStep}
              />
            )}
          />

          <Route
            path="/identification"
            exact
            element={navigateTo(
              accountApplication,
              <IdentificationForm
                accountApplication={accountApplication}
                setAccountApplication={setAccountApplication}
                setCurrentStep={setCurrentStep}
              />
            )}
          />

          <Route
            path="/review"
            exact
            element={navigateTo(
              accountApplication,
              <ReviewForm
                accountApplication={accountApplication}
                setCurrentStep={setCurrentStep}
                hasPhoneVerification={hasPhoneVerification}
                setHasPhoneVerification={setHasPhoneVerification}
              />
            )}
          />

          <Route path="application-referred" exact element={<ReferralPage />} />

          <Route path="application-denied" exact element={<RejectionPage />} />
        </Routes>
      )
    );
  };

  const MergeBody = (
    <div className="flex flex-col items-center overflow-auto innerContainer mobile:pr-0 tablet:pr-0 desktop:pr-5 w-full">
      <EnrollmentProgressBar currentStep={currentStep} steps={steps} />
      {body()}
    </div>
  );

  return <MainTemplate body={MergeBody} images={imagesLogin} logo={false} />;
};

export default AccountApplication;
