import React, { useState, useEffect } from 'react';
import { DeleteForever, Info } from '@mui/icons-material';
import { Form, Formik, FieldArray } from 'formik';
import * as Yup from 'yup';
import CustomInput from '../Inputs/CustomInput';
import CustomSelect from '../Inputs/CustomSelect';
import Button from '@mui/material/Button';
import { INCOME_TYPE, INCOME_GOALS_INITIAL_VALUES } from '../../../utilities/constants';
import {
  postCreateUpdateIncomeGoals,
  deleteIncomeGoal,
} from '@helpers/api/tracker';
import CurrencyFormat from 'react-currency-format';
import { DateTime } from 'luxon';
import Swal from 'sweetalert2';
import BudgetingBuddyComponent from '../../shared/BudgetingBuddy';

const validationSchema = Yup.object({
  income_goals: Yup.array().of(
    Yup.object().shape({
      amount: Yup.string()
        .test(
          'maxDigitsAfterDecimal',
          'Monthly amount must be a valid number with a maximum of 2 decimal.',
          (number) => /^\d+(\.\d{1,2})?$/.test(number)
        )
        .required('This field is required'),
      category: Yup.string().required('This field is required')
    })
  )
});

const IncomeGoalsBase = ({
  handleNext = null,
  incomeGoals,
  handleBack = null,
  setIncomeGoals,
  headingText,
  trusteePayment,
  date = null
}) => {
  const [alert, setAlert] = useState(false);
  const [valueEnough, setValueEnough] = useState(0);
  const [incomeGoalsList, setIncomeGoalsList] = useState({
    income_goals: INCOME_GOALS_INITIAL_VALUES
  });

  useEffect(() => {
    if (incomeGoals.length > 0) {
      setIncomeGoalsList({ income_goals: incomeGoals });
    } else {
      setIncomeGoalsList({ income_goals: INCOME_GOALS_INITIAL_VALUES });
    }
  }, [incomeGoals]);

  const removeFromList = async (i, values, setValues) => {
    const income_goals_data = [...values.income_goals];
    let result;
    if (income_goals_data[i].id) {
      result = await deleteIncomeGoal(income_goals_data[i].id)({ params: { month: date }}).catch((e) =>
        console.error(e)
      );
      setIncomeGoals(result.data.income_goals);
    }
    income_goals_data.splice(i, 1);
    handleEnoughIncome({ income_goals: income_goals_data });
    setValues({
      income_goals: income_goals_data
    });
  };

  const updateForm = (values, setValues) => {
    const income_goals = [...values.income_goals];
    income_goals.push({
      amount: '',
      category: '',
      effective_date: date
    });
    setValues({ income_goals });
  };

  const handleSubmit = async (values) => {
    if (handleEnoughIncome(values)) {
      return false;
    }
    if (values.income_goals?.length > 0) {
      try {
        const response = await postCreateUpdateIncomeGoals({
          data: {
            month: date,
            income_goal: {
              items: values.income_goals
            }
          }
        });
        if (response.status === 200) {
          setIncomeGoals(response.data.income_goals);
          setIncomeGoalsList({ income_goals: response.data.income_goals });
          !handleNext && Swal.fire({
            title: 'Success!',
            text: 'Your income goals have been updated.',
            icon: 'success'
          });
          handleNext && handleNext();
        }
      } catch (error) {
        console.error(error.message);
      }
    } else {
      handleNext && handleNext();
    }
  };

  const handleEnoughIncome = (values) => {
    const incomeAmount =
      values.income_goals?.reduce((acc, cur) => Number(acc) + Number(cur.amount), 0) || 0;

    setValueEnough(Number(trusteePayment.amount) - incomeAmount);
    setAlert(Number(trusteePayment.amount) > incomeAmount);
    return Number(trusteePayment.amount) > incomeAmount;
  };

  return (
    <>
      <BudgetingBuddyComponent />
      <h3 className="mb-6 text-xl text-center font-semibold">{headingText}</h3>
      {alert && (
        <div className="mb-6 text-sm bg-redDarker font-bold text-white p-4 rounded-md">
          <p>
            You have not entered enough income to cover your monthly trustee payment. Please verify
            your income and trustee payment amounts.
          </p>
          <p className="mt-4 font-bold text-center text-lg">
            Remaining amount to cover:{' '}
            <CurrencyFormat
              value={valueEnough}
              displayType="text"
              thousandSeparator
              decimalScale={2}
              fixedDecimalScale
              prefix="$"
            />
          </p>
        </div>
      )}
      <p className="mb-8 text-sm text-center text-gray-600">
        <Info className="inline-block mr-2" />
        Tip: Your income and its source is on Schedule I of your bankruptcy petition.
      </p>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Formik
          initialValues={incomeGoalsList}
          onSubmit={handleSubmit}
          enableReinitialize
          validationSchema={validationSchema}>
          {({ values, setValues, isSubmitting }) => (
            <Form style={{ width: '100%', maxWidth: '500px' }}>
              <FieldArray name="income_goals">
                {() =>
                  values.income_goals.map((item, i) => (
                    <div
                      key={i}
                      className="flex flex-col desktop:flex-row justify-center items-center desktop:gap-8 w-full">
                      <CustomInput
                        name={`income_goals.${i}.amount`}
                        placeholder="Amount"
                        icon="fa-dollar-sign"
                        currency
                      />
                      <CustomSelect
                        name={`income_goals.${i}.category`}
                        placeholder="Income Type"
                        options={INCOME_TYPE}
                      />
                      <button
                        className="cursor-pointer mb-7"
                        type="button"
                        onClick={() => removeFromList(i, values, setValues)}>
                        <DeleteForever />
                      </button>
                    </div>
                  ))
                }
              </FieldArray>
              <button
                className="bg-seafoam text-white px-4 py-2 rounded hover:bg-teal"
                type="button"
                onClick={(e) => updateForm(values, setValues)}>
                + Add additional income source
              </button>
              <div>
                <Button
                  disabled={isSubmitting}
                  variant="contained"
                  type="submit"
                  sx={{ mt: 1, mr: 1 }}>
                  {handleNext ? 'Continue' : 'Update'}
                </Button>

                {handleBack && (
                  <Button onClick={handleBack} sx={{ mt: 1, mr: 1 }}>
                    Back
                  </Button>
                )}
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default IncomeGoalsBase;
