import React, { useContext, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import OptionsFunding from '@components/OptionsFunding';
import SignOutButton from '@components/SignOutButton';
import FormTitle from '@components/FormTitle';
import FormSubtitle from '@components/FormSubtitle';
import { accounts as accountApi } from '@helpers/api';
import { EnrollmentContext } from '@contexts';
import { SessionContext } from '@contexts';

const FundingNav = ({ title, description }) => {
  const { enrollment } = useContext(EnrollmentContext);
  const { user, setUser } = useContext(SessionContext);
  const navigate = useNavigate();
  const swalPopUp = withReactContent(Swal);

  const reactivateOnClick = useCallback((event) => {
    const pathname = event.currentTarget.pathname;
    event.preventDefault();
    if (user.has_closed_account && user.closed_account_reason === 'lack_of_funding') {
      accountApi
        .reactivate()
        .then(() => {
          swalPopUp
            .fire({
              position: 'center',
              icon: 'success',
              title: 'Your account has been reactivated',
              showConfirmButton: true,
              timer: 1500
            })
            .then(() =>{
              setUser({
                ...user,
                has_closed_account: false,
              });
              navigate(pathname);
            })
        })
        .catch(() => {
          swalPopUp
            .fire({
              position: 'center',
              icon: 'failure',
              title: 'There was an error reactivating your account',
              showConfirmButton: true,
              timer: 1500
            })
        })
    } else {
      navigate(pathname);
    }
  })

  const body = (
    <>
      <ul className="flex flex-col justify-center items-center gap-4 mobile:px-5">
        <OptionsFunding
          description="Direct Deposit"
          link="payroll"
          title="Salary / Payroll"
          id="salary-payroll"
          icon="fa-regular fa-wallet"
          onClick={reactivateOnClick}
        />
        {user.features.plaid && (
          <OptionsFunding
            description="Pull funds from your external bank account"
            link="ach/create-transaction"
            title="Link Your Bank Account"
            id="bank-account"
            icon="fa-regular fa-building-columns"
            onClick={reactivateOnClick}
          />
        )}
        <OptionsFunding
          description="Push funds from your external bank account"
          link="bank-account"
          title="Transfer From Your Bank Account"
          id="bank-account"
          icon="fa-regular fa-building-columns"
          onClick={reactivateOnClick}
        />
      </ul>
      {enrollment && <SignOutButton isFunding />}
    </>
  );

  return (
    <>
      <FormTitle>{title}</FormTitle>
      <FormSubtitle>{description}</FormSubtitle>
      {body}
    </>
  );
};

export default FundingNav;
