import React from 'react';
import { Route, Routes } from 'react-router-dom';
import FundingNav from './FundingNav';
import Plaid from './Plaid';
import AchTransactionForm from './AchTransactionForm';
import AchTransactionsList from './AchTransactionsList';
import PayrollForm from './PayrollForm';
import AchSuccess from './AchSuccess';
import BankAccount from './BankAccount';

const FundingRoutes = ({ setLoading, navTitle, navDescription }) => {
  return (
    <Routes>
      <Route path="/" element={<FundingNav title={navTitle} description={navDescription} />} />
      <Route path="/plaid/*" element={<Plaid />} />
      <Route
        exact
        path="/ach/create-transaction/:achAccountId"
        element={<AchTransactionForm setLoading={setLoading} />}
      />
      <Route path="/ach/create-transaction/confirmation" element={<AchSuccess />} />
      <Route
        exact
        path="/ach/create-transaction"
        element={<AchTransactionForm setLoading={setLoading} />}
      />
      <Route
        exact
        path="/ach/transactions-list"
        element={<AchTransactionsList setLoading={setLoading} />}
      />
      <Route path="/bank-account" element={<BankAccount />} />
      <Route path="/payroll" element={<PayrollForm />} />
    </Routes>
  );
};

export default FundingRoutes;
