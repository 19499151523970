import React from 'react';
import { Button, Box, Paper, Typography } from '@mui/material';
import BudgetingBuddyComponent from '../../shared/BudgetingBuddy';

const WelcomeComponent = ({ handleNext, isMonthly = false }) => {
  const WelcomeComponentMonthlyText = () => {
    return (
      <>
        <Typography variant="h5" gutterBottom mt={2}>
          It's time to update your goals for next month
        </Typography>
        <Typography variant="subtitle1" textAlign="center">
          In order to help you stay on your track to success I will guide you through this
        </Typography>
        <Typography variant="subtitle1" textAlign="center">
          coming months payments to make sure you stay on track!
        </Typography>
      </>
    );
  };

  const WelcomeComponentText = () => {
    return (
      <>
        <Typography variant="subtitle1" textAlign="center">
          Hello, I'm Dollar Bill and I'm going to help you track your
        </Typography>
        <Typography variant="subtitle1" textAlign="center">
          expenses so you never miss a Chapter 13 trustee payment.
        </Typography>
      </>
    );
  };

  return (
    <Paper elevation={0}>
      <BudgetingBuddyComponent
        copy={isMonthly ? WelcomeComponentMonthlyText : WelcomeComponentText}
      />
      <Box display="flex" justifyContent="center">
        <Button variant="contained" color="primary" onClick={handleNext}>
          Get Started
        </Button>
      </Box>
    </Paper>
  );
};

export default WelcomeComponent;
